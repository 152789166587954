import React, { useEffect } from "react";
import About from "./aboutUs";
import fontawesome from '@fortawesome/fontawesome'
import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid'
import Service from "./service";
import { Link } from "react-router-dom";

const styles = {
    height: "70%",
    width: "100%"
};

fontawesome.library.add(faCheckSquare, faCoffee);

const Home = () => {
    useEffect(() => {
        const carousel = document.querySelector('#carousel');
        const carouselInstance = new window.bootstrap.Carousel(carousel, {
            interval: 2000
        });
        carouselInstance.cycle();

        window.scrollTo(0, 0);

    }, []);

    return (
        <>
           <div className="wrapper">
                <div id="carousel" className="carousel slide" data-ride="carousel" data-interval="2000">
                    <ol className="carousel-indicators">
                        <li data-target="#carousel" data-slide-to="0" className="active"></li>
                        <li data-target="#carousel" data-slide-to="1"></li>
                        <li data-target="#carousel" data-slide-to="2"></li>
                        <li data-target="#carousel" data-slide-to="3"></li>
                        <li data-target="#carousel" data-slide-to="4"></li>
                        <li data-target="#carousel" data-slide-to="5"></li>
                        <li data-target="#carousel" data-slide-to="6"></li>
                        <li data-target="#carousel" data-slide-to="7"></li>
                        <li data-target="#carousel" data-slide-to="8"></li>
                        <li data-target="#carousel" data-slide-to="9"></li>

                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="/img/carousel-1.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">We Are Professional</p>
                                <h1 className="animated fadeInLeft">For Your Dream Car</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-2.jpg" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">Professional Engineers</p>
                                <h1 className="animated fadeInLeft">We Make Your Accidental Cars</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-3.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <p className="animated fadeInRight">We Are Trusted</p>
                                <h1 className="animated fadeInLeft">For Your Dream Cars</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-4.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">We Provide Crane Service</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-5.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">Shri Ji Automobiles</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-6.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">Hard Working Mechanic</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-7.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">Ac Work</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-8.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">Car Insurance</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="/img/carousel-9.jpg" className="img-fluid" alt="Carousel Image" />
                            <div className="carousel-caption">
                                <h1 className="animated fadeInLeft">Alignment Work</h1>
                                <Link className="btn animated fadeInUp" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                {/* <!-- Carousel End --> */}

                {/* <!-- Feature Start--> */}
                <div className="feature wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div className="feature-icon1">
                                        <div >
                                            <img style={styles} src="/img/worker-logo.jpg" alt="Worker Logo" />
                                        </div>
                                    </div>
                                    <div className="feature-text">
                                        <h3>Expert Worker</h3>
                                        <p>
                                            Our skilled and experienced team is the backbone of our commitment to excellence in car repair services. With specialized skills and attention to detail, we ensure every vehicle receives top-quality care.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div >
                                        <img style={styles} src="/img/Quality-logo.jpg" alt="Worker Logo" />
                                    </div>
                                    <div className="feature-text">
                                        <h3>Quality Work</h3>
                                        <p>Our skilled technicians are dedicated to delivering superior results, prioritizing attention to detail and utilizing cutting-edge techniques and equipment. From minor repairs to major overhauls, each task is approached with utmost care and dedication.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="feature-item">
                                    <div >
                                        <img style={styles} src="/img/24-7.png" alt="Worker Logo" />
                                    </div>
                                    <div className="feature-text">
                                        <h3>24/7 Support</h3>
                                        <p>
                                            We recognize that car repairs aren't bound by traditional business hours.
                                            That's why we're delighted to provide round-the-clock support to our valued clients.
                                            Our dedicated team of professionals is always available to assist you with your automotive needs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Service />
            <About />
        </>
    );
}

export default Home;