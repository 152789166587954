import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <div className="footer wow fadeIn" data-wow-delay="0.3s">
                <div className="footer-container">
                    <div className="row footeritems">
                        <div className="col-md-3 col-lg-3">
                            <div className="footer-contact">
                                <h2>Office Contact</h2>
                                <p> <i className="fa fa-map-marker-alt"> </i>Shri Ji Automobiles, Main Tonk Road , Near Fauji Bhai Petrol Pump Bilwa, Jaipur-302022</p>
                                <i className="fa fa-phone-alt"></i>  <a href="tel:8946904510"> +91 8946904510</a>
                                <br /> <i className="fa fa-phone-alt"></i>  <a href="tel:7976255083"> +91 7976255083</a>
                                <p><i className="fa fa-envelope"></i>  <a href="mailto:ankitsharma.as703@gmail.com" className="email-button">ankitsharma.as703@gmail.com</a></p>
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=61558518353481&mibextid=ZbWKwL" target="_self"><i className="fab fa-facebook-f"></i></a>
                                    <a href="/" target="_self" style={{ marginLeft: "3px" }}><i className="fab fa-instagram" ></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <h2>Services Areas</h2>

                            <div className="row" style={{ display: "flex", flexDirection: "row" }} >
                                 <div className="footer-link col-lg-6 col-md-6">
                                    <Link>Car washing</Link>
                                    <Link>Alignment</Link>
                                    <Link>Tyre Balancing</Link>
                                    <Link>Ceramic Coating</Link>
                                    <Link>Rubber Coating</Link>
                                    <Link>Rubbing</Link>
                                    <Link>Denting & Painting</Link>
                                    <Link>Dryclean</Link>
                                 </div>
                              <div className="footer-link col-lg-6 col-md-6">
                                <Link>Accidental Work</Link>
                                <Link>Car AC Work</Link>
                                <Link>Engine Diagnosis</Link>
                                <Link>Suspension</Link>
                                <Link>Wiring Work</Link>
                                <Link>Insurance Work</Link>
                                <Link>Insurance Renew</Link>
                                <Link>Towing Work</Link>
                            </div>
                            </div>


                        </div>

                        <div className="col-md-3 col-lg-3">
                            <div className="footer-link">
                                <h2>Useful Pages</h2>
                                <Link to="/about">About Us</Link>
                                <Link to="/contact">Contact Us</Link>
                                <Link to="/service">Our Services</Link>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <div className="newsletter">
                                <h2>Chat With Us</h2>
                                <div className="footer-social">
                                    <a href="https://api.whatsapp.com/send?phone=918946904510&text=Send20%a20%quote" target="_self"><i className="fab fa-whatsapp " aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container footer-menu">
                    <div className="f-menu">
                        <p className="p1">Thanks For Visit </p>
                        <p>Shri Ji Automobiles </p>
                    </div>
                </div>
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <p>&copy; <a href="#">Shri Ji Automobiles </a>, All Right Reserved.</p>
                        </div>
                        <div className="col-md-6">
                            <p>Developed By <a href="https://www.acorebit.com" title="www.acorebit.com">ACoreBit it solutions, Jaipur</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;