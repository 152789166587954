import React, { useEffect } from "react";
const Service = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="service">
                <div className="container">
                    <div className="section-header text-center">
                        <h2>We Provide Services</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-1.jpg" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Accidental Work</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-2.jpg" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Rubbing</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-3.jpg" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Engine Diagnosis</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-4.jpg" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Car Washing</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-5.jpg" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Denting-Penting</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="img/service-6.avif" alt="Image" />
                                </div>
                                <div className="service-text">
                                    <h3>Suspension</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Service;