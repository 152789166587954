import React, { useEffect } from "react";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-7">
                            <div className="about-img">
                                <img style={{ width: "90%", marginTop: "26px" }} src="img/service-4.jpg" alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 welcometxt ">
                            <h2 className="mt-5" style={{ color: "brown" }}>Car Washing And Detailing</h2>
                            <div className="section-header text-left">
                                <h3>10+ Years Experience</h3>
                            </div>
                            <div className="about-text">
                                <div className="about wow fadeInUp" data-wow-delay="0.1s">
                                    <p>
                                        Welcome to <span style={{ color: "brown", fontSize: "larger" }}>Shri Ji Automobiles</span>, where we're passionate about transforming your vehicle into a gleaming masterpiece on wheels. With years of experience and a commitment to excellence, we offer top-tier car washing and detailing services tailored to meet your every need                        </p>
                                    <p>
                                        Our mission is simple: to elevate your driving experience by providing unparalleled car care solutions. We understand that your vehicle is more than just a mode of transportation; it's an investment, a reflection of your personality, and a source of pride. That's why we strive to deliver exceptional results with every wash and detail, ensuring that your car not only looks its best but also feels rejuvenated both inside and out.                        </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="wrapper">

                <div className="fact">
                    <div className="container-fluid">
                        <div className="row counters">

                            <div className="col-md-6 fact-right wow slideInRight">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">10<sup>+</sup></h2>
                                            <p>Years Experience</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">5</h2>
                                            <p>Service Points</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 fact-left wow slideInLeft">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">500<sup>+</sup></h2>
                                            <p>Happy Clients</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="fact-text">
                                            <h2 data-toggle="counter-up">20<sup>+</sup></h2>
                                            <p>Engineers & Workers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;