import React, { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';


const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const form = useRef();

    const sendEmail = (e) => {
        const YOUR_SERVICE_ID = "service_lxlrbdo";
        const template_id = "template_8dhf8z8";
        const public_key = "OrfUi-GNjqk1Z4b2v";
        e.preventDefault();

        emailjs
            .sendForm(YOUR_SERVICE_ID, template_id, form.current, {
                publicKey: public_key,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    window.alert("mail send successful")
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className="contact wow fadeInUp">
            <div className="container">
                <div className="section-header text-center">
                    <h2>For Any Query</h2>
                </div>
                <div className="row querydiv">
                    <div className="col-md-6">
                        <div className="contact-info">
                            <div className="contact-item">
                                <i className="flaticon-address"></i>
                                <div className="contact-text">
                                    <h2>Location</h2>
                                    <span style={{color:"#59D5E0",fontSize:"larger"}}> Shri Ji Automobiles,</span> <span style={{color:"white"}}> Main Tonk Road , Near Fauji Bhai Petrol Pump Bilwa, Jaipur-302022</span>
                                </div>
                            </div>
                            <div className="contact-item">
                                <i className="flaticon-call"></i>
                                <div className="contact-text">
                                    <h2>Phone</h2>
                                    <a href="tel:8946904510">  <i className="fa fa-phone-alt"> </i>+91 8946904510</a>
                                </div>
                            </div>
                            <div className="contact-item">
                                <i className="flaticon-send-mail"></i>
                                <div className="contact-text">
                                    <h2>Email</h2>
                                    <a href="mailto:ankitsharma.as703@gmail.com" className="email-button"> ankitsharma.as703@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-form">
                            <div id="success"></div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="control-group">
                                    <input type="text" name="from_name" className="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" name="from-email" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" id="message" name="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button className="btn" type="submit" id="sendMessageButton">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;

