import react, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');
    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location]);

    const [toggle, setToggle] = useState(false);

    const togglebar = () => {
        setToggle(!toggle);
    };

    return (
        <div className="headermain">
            <div className="top-bar">
                <div className="subheader">
                    <div className="row align-items-center header-responsive">
                        <div className="col-lg-4 col-md-6 m-header">
                            <div className="logo-div">
                                <Link to="/"><img className="logo_icon" src="img/company-logo.jpg"></img></Link></div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 mt"> */}
                            <div className=" col-md-6 topbar-social-icon">
                                <div className="footer-social">
                                    <a href="https://www.facebook.com/profile.php?id=61558518353481&mibextid=ZbWKwL" target="_self"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/bagda9786?igsh=MWViMXZxcjZxd3VuNg==" target="_self"><i className="fab fa-instagram"></i></a>
                                </div>
                            {/* </div> */}
                        </div>
                        <div className="col-lg-8 col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-text">
                                            <h3>Opening Day</h3>
                                            <p>Mon - Sun, 8:00 am - 9:00 pm</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">
                                        <div className="top-bar-text">
                                            <h3>Call Us</h3>
                                            <a href="tel:8946904510">+91 8946904510</a>
                                            <br /> <a href="tel:8946904510">+91 7976255083</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-bar-item">

                                        <div className="top-bar-text">
                                            <h3>Email Us</h3>
                                            <a href="mailto:ankitsharma.as703@gmail.com" className="email-button">ankitsharma.as703@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-bar">
                <div className="subheader">
                    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
                        <a href="#" className="navbar-brand">MENU</a>
                        <button type="button" onClick={togglebar} className="navbar-toggler">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${toggle ? 'show' : ''}`} id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <Link to="/" className={activeItem === '/' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Home</Link>
                                <Link to="/about" className={activeItem === '/about' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>About</Link>
                                <Link to="/service" className={activeItem === '/service' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Service</Link>
                                <Link to="/contact" className={activeItem === '/contact' ? 'nav-item nav-link active' : 'nav-item nav-link'} onClick={togglebar}>Contact</Link>
                            </div>
                            <div className="ml-auto">
                                <Link className="btn" to="/contact">Get A Quote</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div >
    );
}
export default Header;