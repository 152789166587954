import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/header';
import Home from './components/home';
import About from './components/aboutUs';
import Contact from './components/contact';
import Footer from './components/footer';
import Service from './components/service';

function App() {  
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path='/contact' element={<Contact />}/>
        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;

